import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ProductList.css';
import PaymentModal from './PaymentModal'; // PaymentModal importieren

// Funktion zur Formatierung der Preise mit € vor der Zahl
const formatPrice = (price) => {
  return `€ ${price.toFixed(2).replace('.', ',')}`;
};

const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

// Funktion mit Timeout für Fetch-Request
const fetchWithTimeout = (url, options, timeout = 5000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Timeout')), timeout)
    ),
  ]);
};

function ProductList() {
  const [products, setProducts] = useState([]);
  const { kategorie } = useParams();
  const [order, setOrder] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [notification, setNotification] = useState({ message: '', type: '', visible: false });
  const [pendingOrders, setPendingOrders] = useState(
    JSON.parse(localStorage.getItem('pendingOrders')) || []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false); // Status für das Modal
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [givenAmount, setGivenAmount] = useState('0.00'); // Hier fügen wir den givenAmount hinzu

  const handleGivenAmountChange = (amount) => {
    setGivenAmount(amount); // Aktualisiere den State, wenn der Betrag im PaymentModal geändert wird
  };
 

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/kasse?kategorie=${kategorie}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Fehler: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => setProducts(data))
      .catch((error) => {
        console.error('Fehler:', error.message);
      });
  }, [kategorie]);

  const saveOrderToLocal = (order) => {
    const updatedPendingOrders = [...pendingOrders, order];
    localStorage.setItem('pendingOrders', JSON.stringify(updatedPendingOrders));
    setPendingOrders(updatedPendingOrders);

    setNotification({ message: 'Bestellung in Warteschlange gespeichert', type: 'queue', visible: true });
    setTimeout(() => setNotification({ ...notification, visible: false }), 2000);
    clearCurrentOrder();
  };

  const removeOrderFromLocal = (orderIndex) => {
    const updatedPendingOrders = [...pendingOrders];
    updatedPendingOrders.splice(orderIndex, 1);
    localStorage.setItem('pendingOrders', JSON.stringify(updatedPendingOrders));
    setPendingOrders(updatedPendingOrders);
  };

  const retryPendingOrders = () => {
    pendingOrders.forEach((pendingOrder, index) => {
      submitOrder(pendingOrder, index, false);
    });
  };

  const clearCurrentOrder = () => {
    setOrder([]);
    setTotalPrice(0);
  };

  const addToOrder = (product) => {
    setOrder([...order, product]);
    setTotalPrice(totalPrice + product.price);
  };


  const removeFromOrder = (indexToRemove) => {
    const newOrder = order.filter((_, index) => index !== indexToRemove);
    const removedItem = order[indexToRemove];
    setOrder(newOrder);
    setTotalPrice(totalPrice - removedItem.price);
  };

  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
    setTimeout(() => {
      submitOrder(order, method, null, true);
      // Zurücksetzen der Zahlungsart und des Modals nach dem Abschicken
      setSelectedPaymentMethod(null);
      setIsPaymentModalOpen(false);  // Schließe das Modal nach der Bestellung
    }, 0);
  };


  const submitOrder = (order, paymentMethod, orderIndex = null, lockButton = true) => {
    console.log("Zahlungsart:", paymentMethod);
    if (lockButton) setIsSubmitting(true); 
  
    const token = localStorage.getItem('token'); // Token aus dem localStorage holen
    
    // Den gegebenen Betrag (givenAmount) hinzufügen
    const requestBody = {
      order,
      paymentMethod,
      givenAmount: givenAmount // Füge den gegebenen Betrag hier hinzu
    };
  
    fetchWithTimeout(`${process.env.REACT_APP_API_BASE_URL}/api/submitOrder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Token im Authorization-Header mitschicken
      },
      body: JSON.stringify(requestBody), // Den Körper der Anfrage mit den Daten
    })
      .then((response) => {
        if (response.ok) {
          if (orderIndex !== null) {
            removeOrderFromLocal(orderIndex); 
          }
          setNotification({
            message: 'Bestellung erfolgreich übermittelt!',
            type: 'success',
            visible: true,
          });
          clearCurrentOrder();
          setSelectedPaymentMethod(null); // Zahlungsart zurücksetzen
          setGivenAmount('0.00'); // GivenAmount zurücksetzen
        } else {
          if (orderIndex === null) {
            saveOrderToLocal(order); 
          }
          setNotification({
            message: 'Fehler beim Übermitteln der Bestellung! Erneuter Versuch...',
            type: 'error',
            visible: true,
          });
        }
        setTimeout(() => setNotification({ ...notification, visible: false }), 2000);
        if (lockButton) setIsSubmitting(false); 
      })
      .catch((error) => {
        console.error('Netzwerkfehler:', error.message);
        if (error.message === 'Failed to fetch' || error.message === 'Timeout') {
          if (orderIndex === null) {
            saveOrderToLocal(order);
          }
          setNotification({
            message: 'Keine Verbindung. Bestellung wird lokal gespeichert.',
            type: 'queue',
            visible: true,
          });
          setTimeout(() => setNotification({ ...notification, visible: false }), 2000);
        }
        if (lockButton) setIsSubmitting(false); 
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      retryPendingOrders();
    }, 5000); 

    return () => clearInterval(intervalId); 
  }, [pendingOrders]);

  return (
    <div>
      {/* Logo und Überschrift */}
      <div className="header-container">
        <img src="https://www.von-der-weide.de/img/logo.jpg" alt="Von der Weide Logo" className="logo" />
        <h1 className="page-header">Kasse - {capitalizeFirstLetter(kategorie)}</h1>
      </div>

      <div className="container">
        <div className="products-list">
          <div className="products-grid">
            {products.map((product) => (
              <div
                className="product-item"
                key={product.id}
                onClick={() => addToOrder(product)}
                style={{
                  cursor: 'pointer',
                  backgroundImage: product.image ? `url(${product.image})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  color: '#fff',
                  position: 'relative',
                }}
              >
                <div className="overlay"></div>
                <div className="card-body">
                  <h5 className="card-title">{product.name}</h5>
                  <p className="card-text">{product.info}</p>
                  <p className="card-text">{formatPrice(product.price)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="order-summary">
          <h2>Bestellung</h2>
          <div className="order-items">
            <ul className="list-group">
              {order.map((item, index) => (
                <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                  <span>{item.name} - {formatPrice(item.price)}</span>
                  <button
                    className="btn btn-danger btn-sm"
                    style={{ width: '30px', height: '30px', padding: '0', textAlign: 'center', lineHeight: '30px' }}
                    onClick={() => removeFromOrder(index)}
                  >
                    &times;
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <h3 className="mt-3">Gesamtpreis: {formatPrice(totalPrice)}</h3>
          <button 
            className={`btn ${order.length === 0 ? 'btn-secondary' : 'btn-success'} mt-2`}
            onClick={() => setIsPaymentModalOpen(true)}  // Öffnet das Zahlungsarten-Modal
            disabled={order.length === 0 || isSubmitting} 
            
          >
            {isSubmitting ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 
            ) : (
              'Bestellung abschließen'
            )}
          </button>
        </div>
      </div>

      {/* PaymentModal anzeigen */}
      <PaymentModal
  isOpen={isPaymentModalOpen}
  onClose={() => setIsPaymentModalOpen(false)}
  onPaymentMethodSelect={handlePaymentMethodSelect}
  totalPrice={totalPrice}  // Den Gesamtpreis korrekt an PaymentModal übergeben
  onGivenAmountChange={handleGivenAmountChange}  // Übergebe die Funktion hier
/>

      {/* Benachrichtigungs-Overlay */}
      {notification.visible && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
}

export default ProductList;