import React, { useState, useEffect } from 'react';
import './PaymentModal.css';

function PaymentModal({ isOpen, onClose, onPaymentMethodSelect, totalPrice, onGivenAmountChange }) {
    const [givenAmount, setGivenAmount] = useState('0.00');
    const [change, setChange] = useState(0);
    const [isCashPayment, setIsCashPayment] = useState(false);
    const [showCashOptions, setShowCashOptions] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Ladestatus für das Spinning Wheel
    const [paymentStatus, setPaymentStatus] = useState(null); // Status der Zahlung
    const [clientTransactionId, setClientTransactionId] = useState(null); // Transaction ID für die Statusabfrage

    const cashOptions = [5, 10, 20, 50, 100];

    useEffect(() => {
      if (isOpen) {
        setGivenAmount('0.00');
        setChange(0);
        setIsCashPayment(false);
        setShowCashOptions(false);
        setPaymentStatus(null);
        setIsLoading(false);
        setClientTransactionId(null);
      }
    }, [isOpen]);

    useEffect(() => {
      onGivenAmountChange(givenAmount);
    }, [givenAmount, onGivenAmountChange]);

    const calculateChange = (amount) => {
      const numericAmount = parseFloat(amount);
      if (numericAmount >= totalPrice) {
        setChange((numericAmount - totalPrice).toFixed(2));
      } else {
        setChange(0);
      }
    };

    const addToGivenAmount = (digit) => {
      const newAmount = `${givenAmount}${digit}`;
      const formattedValue = (parseFloat(newAmount.replace(/\./g, '')) / 100).toFixed(2);
      setGivenAmount(formattedValue);
      calculateChange(formattedValue);
    };

    const removeLastDigit = () => {
      const newAmount = givenAmount.slice(0, -1) || '0';
      const formattedValue = (parseFloat(newAmount.replace(/\./g, '')) / 100).toFixed(2);
      setGivenAmount(formattedValue);
      calculateChange(formattedValue);
    };

    const handleCashSelect = (amount) => {
      setGivenAmount(amount.toFixed(2));
      calculateChange(amount);
    };

    const handleSubmit = (method, clientTransactionId = null) => {
      if (method === 'bar' && parseFloat(givenAmount) < totalPrice) {
        alert('Der gegebene Betrag reicht nicht aus.');
        return;
      }
      const transactionMethod = clientTransactionId ? `${method}-${clientTransactionId}` : method;
      onPaymentMethodSelect(transactionMethod); // Speichert die Bestellung mit der client_transaction_id wie gewünscht
      onClose();
    };

    const sendCardPayment = () => {
      const token = localStorage.getItem('token');

      const paymentPayload = {
        amount: totalPrice,
        currency: "EUR",
        description: "Bezahlung der Bestellung",
        tipRates: [0.03, 0.05, 0.1]
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/sendPayment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(paymentPayload)
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Serverfehler: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          console.error('Fehler bei der Kartenzahlung:', data.error);
          alert('Es gab einen Fehler bei der Kartenzahlung');
        } else {
          setClientTransactionId(data.client_transaction_id); // Speichern der Transaction ID
          startPollingPaymentStatus(data.client_transaction_id);
        }
      })
      .catch((error) => {
        console.error('Netzwerk- oder Serverfehler:', error.message);
        alert('Fehler bei der Kommunikation mit dem Server');
      });
    };

    const startPollingPaymentStatus = (clientTransactionId) => {
      setIsLoading(true);

      const intervalId = setInterval(() => {
        if (clientTransactionId) {
          fetch(`${process.env.REACT_APP_API_BASE_URL}/api/checkPaymentStatus?client_transaction_id=${clientTransactionId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'successful') {
              handleSubmit('karte', clientTransactionId); // Bestellung mit client_transaction_id speichern
              setIsLoading(false);
              clearInterval(intervalId);
            } else if (data.status === 'failed') {
              setIsLoading(false);
              alert('Die Kartenzahlung ist fehlgeschlagen');
              clearInterval(intervalId);
            }
          })
          .catch((error) => {
            console.error('Fehler beim Abrufen des Zahlungsstatus:', error);
          });
        }
      }, 1000); // Jede Sekunde abfragen
    };

    const handlePaymentSelect = (method) => {
      if (method === 'bar') {
        setIsCashPayment(true);
        setShowCashOptions(true);
      } else if (method === 'karte') {
        sendCardPayment();
      }
    };

    const handleBackClick = () => {
      setShowCashOptions(false);
    };

    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal">

          {/* Zurück-Button oben links */}
          {isLoading && (
            <button className="back-btn" onClick={onClose}>←</button>
          )}

          {/* Spinning Wheel anzeigen, wenn Zahlung läuft */}
          {isLoading ? (
            <div className="spinner-container">
              <div className="spinner-border" role="status">
              </div>
              <p>Zahlung wird verarbeitet...</p>
            </div>
          ) : (
            <>
              {showCashOptions && (
                <button className="back-btn" onClick={handleBackClick}>←</button>
              )}

              <h2>Zahlungsart wählen</h2><p></p>

              {!showCashOptions ? (
                <div className="button-container">
                  <button className="payment-btn" onClick={() => handlePaymentSelect('bar')}>
                    <i className="fas fa-money-bill fa-icon"></i>Bar
                  </button>
                  <button className="payment-btn" onClick={() => handlePaymentSelect('karte')}>
                    <i className="fas fa-credit-card fa-icon"></i>Karte
                  </button>
                </div>
              ) : (
                <>
                  <div className="cash-input">
                    <h4>Betrag eingeben:</h4>
                    <div className="custom-keypad">
                      <div className="keypad-display">{givenAmount}</div>
                      <div className="keypad-rows">
                        <button onClick={() => addToGivenAmount("1")}>1</button>
                        <button onClick={() => addToGivenAmount("2")}>2</button>
                        <button onClick={() => addToGivenAmount("3")}>3</button>
                        <button onClick={() => addToGivenAmount("4")}>4</button>
                        <button onClick={() => addToGivenAmount("5")}>5</button>
                        <button onClick={() => addToGivenAmount("6")}>6</button>
                        <button onClick={() => addToGivenAmount("7")}>7</button>
                        <button onClick={() => addToGivenAmount("8")}>8</button>
                        <button onClick={() => addToGivenAmount("9")}>9</button>
                        <button onClick={() => addToGivenAmount("0")} className="zero-btn">0</button>
                        <button onClick={removeLastDigit} className="delete-btn">⌫</button>
                      </div>
                    </div>
                    <h4>Oder wählen Sie einen Geldschein:</h4>
                    <div className="cash-options">
                      {cashOptions.map((amount) => (
                        <button 
                          key={amount} 
                          className="cash-option-btn" 
                          onClick={() => handleCashSelect(amount)}>
                          <span>€</span> <span>{amount}</span>
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="change-display" style={{ color: change > 0 ? 'black' : 'white' }}>
                    <h3>Rückgeld: € {parseFloat(change).toFixed(2)}</h3>
                  </div>

                  <button onClick={() => handleSubmit('bar')} className="confirm-button">
                    Zahlung abschließen
                  </button>
                </>
              )}
            </>
          )}

        </div>
      </div>
    );
}

export default PaymentModal;