import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import ProductList from './ProductList';
import './App.css';  // Verwende die CSS für das Styling

// Login-Komponente mit schönem Layout
function Login({ setToken }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [category, setCategory] = useState('bar');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();
    if (response.ok) {
      setToken(data.accessToken);
      localStorage.setItem('token', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken); // Speichere den Refresh-Token
      localStorage.setItem('username', username);
      localStorage.setItem('category', category); // Kategorie speichern
      window.location.href = `/kasse/${category}`;
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="https://www.von-der-weide.de/img/logo.jpg" alt="Von der Weide Logo" className="logo" />
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
          <select value={category} onChange={(e) => setCategory(e.target.value)} className="login-select">
            <option value="bar">Bar</option>
            <option value="getränke">Getränke</option>
            <option value="essen">Essen</option>
            <option value="kaffee">Kaffee</option>
          </select>
          <button type="submit" className="login-button">Login</button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
      </div>
    </div>
  );
}

// Funktion zum Abrufen eines neuen Access-Tokens mit dem Refresh-Token
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: refreshToken }),
  });

  const data = await response.json();
  if (response.ok) {
    return data.accessToken; // Gib den neuen Access-Token zurück
  } else {
    console.error('Failed to refresh token');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login';
  }
};

// Funktion zum Abrufen mit Token-Erneuerung
const fetchWithToken = async (url, options = {}) => {
  const token = localStorage.getItem('token');

  if (!options.headers) {
    options.headers = {};
  }

  // Setze den Access-Token im Authorization-Header
  options.headers['Authorization'] = `Bearer ${token}`;

  let response = await fetch(url, options);

  // Falls der Access-Token abgelaufen ist (403 Fehler), hole einen neuen mit dem Refresh-Token
  if (response.status === 403) {
    const newToken = await refreshAccessToken();
    if (newToken) {
      localStorage.setItem('token', newToken);
      options.headers['Authorization'] = `Bearer ${newToken}`;
      response = await fetch(url, options); // Wiederhole die Anfrage mit dem neuen Token
    }
  }

  return response;
};

// Sidebar-Menü für den Rest der App
function SidebarMenu({ setToken }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [category, setCategory] = useState(localStorage.getItem('category') || 'bar');
  const username = localStorage.getItem('username');
  const token = localStorage.getItem('token');
  const decodedToken = token ? JSON.parse(atob(token.split('.')[1])) : null;
  const name = decodedToken ? decodedToken.name : ''; // Den Namen aus dem Token holen

  const handleLogout = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Sende den Token im Authorization-Header
      },
      body: JSON.stringify({ token }), // Falls der Token als Body übergeben werden soll
    })
    .then(() => {
      localStorage.removeItem('token');
      setToken(null);
    })
    .catch((error) => {
      console.error('Logout-Fehler:', error);
    });
  };

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setCategory(newCategory);
    localStorage.setItem('category', newCategory);
    navigate(`/kasse/${newCategory}`);
    setMenuOpen(false); // Menü schließen nach Auswahl
  };

  return (
    <>
      <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={() => setMenuOpen(false)}>
          &times;
        </button>
        <h3>Hallo, {name}</h3>
        <select value={category} onChange={handleCategoryChange} className="menu-select">
          <option value="bar">Bar</option>
          <option value="getränke">Getränke</option>
          <option value="essen">Essen</option>
          <option value="kaffee">Kaffee</option>
        </select>
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>
      <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        &#9776;
      </div>
    </>
  );
}

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    if (!token) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    }
  }, [token]);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={token ? <Navigate to="/" /> : <Login setToken={setToken} />}
        />
        <Route
          path="/"
          element={token ? (
            <div>
              <SidebarMenu setToken={setToken} />
              <ProductList />
            </div>
          ) : (
            <Navigate to="/login" />
          )}
        />
        <Route
          path="/kasse/:kategorie"
          element={token ? (
            <div>
              <SidebarMenu setToken={setToken} />
              <ProductList />
            </div>
          ) : (
            <Navigate to="/login" />
          )}
        />
      </Routes>
    </Router>
  );
}

export default App;